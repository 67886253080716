import React, { useCallback, useMemo } from "react"
import { AspectRatio, Badge, BadgeProps, Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react"

import { useImage } from "@app/hooks/useImage"
import { useShopify, useShopifyPrice, useShopifyProduct } from "@app/hooks/useShopify"
import { Icon } from "@app/components/Icon"

import { useProductContext } from "@app/providers/product"
import { useGlobal } from "@app/hooks/useGlobal"
import { useCart } from "@app/hooks/useCart"
import ProductPrice from "@app/components/Product/ProductPrice"

import { HandleTrackingClickType } from "@app/hoc/Section"
import { NormalisedProduct } from "@root/types/custom-types/Product/Product"

export type ProductItemProps = {
  item: NormalisedProduct
  handleTrackingClick?: HandleTrackingClickType
  onAddHandler?: (product: NormalisedProduct) => void
}

const ProductItem: React.FC<ProductItemProps> = ({ item, handleTrackingClick, onAddHandler }) => {
  const { addToCart, loading: cartLoading } = useCart()
  const { setDrawerProduct, setIsProductDrawerOpen } = useProductContext()
  const {
    global: { productAdd, productAbout, productSale },
  } = useGlobal()
  const { getGatsbyImage } = useImage()
  const { getProductTagValue } = useShopifyProduct()
  const { getFirstAvailableVariant } = useShopify()

  const availableVariant = useMemo(() => getFirstAvailableVariant(item), [getFirstAvailableVariant, item])

  const { onSale } = useShopifyPrice(availableVariant)
  const customImages = useMemo(() => getGatsbyImage(item.images), [item.images, getGatsbyImage])
  const badge = useMemo(() => getProductTagValue("badge:", item.tags) || "", [getProductTagValue, item.tags])

  const onModalClickHandler = useCallback(() => {
    setDrawerProduct(item)
    setIsProductDrawerOpen(true)
  }, [setDrawerProduct, setIsProductDrawerOpen, item])

  const onAddClickHandler = useCallback(() => {
    if (!availableVariant) return

    if (onAddHandler) {
      onAddHandler(item)
      return
    }

    if (handleTrackingClick) handleTrackingClick()
    addToCart({ variantId: availableVariant.id })
  }, [handleTrackingClick, addToCart, availableVariant, item, onAddHandler])

  const addIsDisabled = useMemo(
    () => !onAddHandler && (!availableVariant?.availableForSale || cartLoading),
    [onAddHandler, availableVariant, cartLoading]
  )

  if (!availableVariant) return null

  const badgeCommonStyles: BadgeProps = {
    pos: "absolute",
    top: "0",
    left: "0",
    zIndex: "docked",
  }

  return (
    <Flex flexDir="column" justifyContent="space-between">
      <Box>
        <Box onClick={onModalClickHandler} pos="relative" cursor="pointer">
          {badge && !onSale ? <Badge {...badgeCommonStyles}>{badge}</Badge> : null}

          {onSale ? (
            <Badge variant="tertiary" {...badgeCommonStyles}>
              {productSale}
            </Badge>
          ) : null}

          <AspectRatio ratio={1 / 1} mb="3">
            <Image {...customImages[0]} />
          </AspectRatio>
        </Box>

        <Box>
          <Heading as="h5" size="headlines" color="typography.headlines900">
            {item.title}
          </Heading>

          {item.vendor ? (
            <Text size="mediumParagraph" mb="2">
              {item.vendor}
            </Text>
          ) : null}

          <ProductPrice variant={availableVariant} />

          <Button onClick={onModalClickHandler} variant="unstyled" d="inline-flex" gap="1" h="auto" mb="3">
            <Box as="span" w="4" h="4">
              <Icon name="information-circle" width="100%" height="100%" />
            </Box>

            <Text size="smallParagraph" variant="underlined">
              {productAbout}
            </Text>
          </Button>
        </Box>
      </Box>

      <Button onClick={() => onAddClickHandler()} disabled={addIsDisabled} isLoading={cartLoading} variant="outline" w="full">
        {productAdd}
      </Button>
    </Flex>
  )
}

export default React.memo(ProductItem)
