import React, { useEffect, useRef } from "react"
import { Box, Container, Grid, Heading } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useCheckoutContext } from "@app/providers/checkout"
import { useCore } from "@app/hooks/useCore"
import { useLazyQuery } from "@apollo/client"
import { useShopify } from "@app/hooks/useShopify"
import ProductItem from "@app/components/Product/ProductItem"
import { AnalyticProps, withSection } from "@app/hoc/Section"

import type { ProductTemplateSectionProps } from "@app/components/Product/Product"
import type { NormalisedProduct } from "@root/types/custom-types/Product/Product"

type Props = GatsbyTypes.SanitySectionProductAddOns & ProductTemplateSectionProps & AnalyticProps

const ProductAddOns: React.FC<Props> = ({ menuTitle, title, shopifyProduct, innerRef, handleTrackingClick, tag }) => {
  const {
    graphql: {
      queries: { GET_PRODUCT_RECOMMENDATIONS },
    },
  } = useCore()
  const { countryCode } = useCheckoutContext()
  const { productNormaliser } = useShopify()
  const { saveProductItems } = useAppContext()

  const id = shopifyProduct.id

  const sectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => saveProductItems(menuTitle, sectionRef), [sectionRef, saveProductItems, menuTitle])

  const [getRecommendations, { data, called, loading, error }] = useLazyQuery(GET_PRODUCT_RECOMMENDATIONS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      countryCode,
      id,
      firstMedia: 1,
      firstImages: 1,
      firstVariants: 1,
      firstMetafields: 1,
      firstCollections: 1,
      metafieldIdentifiers: [],
    },
  })

  useEffect(() => {
    if (!id || called) return
    getRecommendations()
  }, [id, called, getRecommendations])

  if (error) console.error(error)

  const recommendations: NormalisedProduct[] = !loading && data?.recommendations?.length ? data.recommendations.map(productNormaliser) : []
  let items = recommendations
  if (recommendations.length > 4) items = recommendations.slice(0, 4)

  return items.length ? (
    <Box as="section" ref={sectionRef} bg="background.white">
      <Container ref={innerRef} maxW="container.2xl" py={{ base: "8", lg: "18" }} px={{ base: "4", lg: "20" }}>
        <Heading as={tag} size="h3" mb={{ base: "5", lg: "8" }} color="typography.headlines900" textAlign="center">
          {title}
        </Heading>

        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
          columnGap={{ base: "4", lg: "8" }}
          rowGap={{ base: "5", lg: "9" }}
        >
          {items.map(item => (
            <ProductItem key={item.id} item={item} handleTrackingClick={handleTrackingClick} />
          ))}
        </Grid>
      </Container>
    </Box>
  ) : null
}

export default React.memo(withSection(ProductAddOns))
